import "./index.css";
const About = () => {
return <div className="about-page">

  <h1>About Us</h1>

    <section className="about-content">
      <p>
        Hogcloud is a team of experts in cloud computing, AI integration, website development, and custom
        software/mobile applications. We offer tailored solutions to help businesses grow and stay competitive.
      </p>

      <p>
        With over 25 years of experience, our mission is to empower clients with cutting-edge technology and strategic
        guidance, enabling them to achieve their goals. Whether you need to enhance your online presence, streamline
        operations, or leverage AI, we're here to help.
      </p>

      <p>
        We focus on collaboration and customer satisfaction, building long-term relationships and delivering exceptional
        results.
      </p>
      <p>Trust us to be your partner in growth and success.</p>
    </section>
</div>
};

export default About;