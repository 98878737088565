import './index.css'
const Contact = () => {
return (
    <div>
        <form>
            <div className="form-row">
                <div className="form-column">
                    <label htmlFor="name">
                        Name
                    </label>
                    <input type="text" id="name" />
                </div>
                <div className="form-column">
                    <label htmlFor="email">
                        Email
                    </label>
                    <input type="email" id="email" />
                </div>
            </div>
            <div>               
                <div className="checkbox-group">   
                    <div className='container'>       
                        <div className='column left'>
                            <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" id="website" name="service_type" value="website" />
                                <label htmlFor="website" style={{marginLeft: '2px'}}>
                                    Website
                                </label>
                            </div>

                            <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" id="cloud-consulting" name="service_type" value="cloud-consulting" />
                                <label htmlFor="cloud-consulting" style={{marginLeft: '2px'}}>
                                    Cloud
                                </label>
                            </div>

                            <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" id="ai" name="service_type" value="ai" />
                                <label htmlFor="ai" style={{marginLeft: '2px'}}>
                                    AI
                                </label>
                            </div>

                            <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" id="development" name="service_type" value="development" />
                                <label htmlFor="development" style={{marginLeft: '2px'}}>
                                    Development
                                </label>
                            </div>
                        </div>
                        <div className='column right'>
                            <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" id="consulting" name="service_type" value="consulting" />
                                <label htmlFor="consulting" style={{marginLeft: '2px'}}>
                                    Consulting
                                </label>
                            </div>
                            <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" id="support" name="service_type" value="support" />
                                <label htmlFor="support" style={{marginLeft: '2px'}}>
                                    Support
                                </label>
                            </div>                           
                            <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" id="tutoring" name="service_type" value="tutoring" />
                                <label htmlFor="tutoring" style={{marginLeft: '2px'}}>
                                    Tutoring
                                </label>
                            </div>  
                            <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                <input type="checkbox" id="other" name="service_type" value="other" />
                                <label htmlFor="other" style={{marginLeft: '2px'}}>
                                    Other
                                </label>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label htmlFor="message">
                    Message
                </label>
                <textarea id="message" rows={3} />
                </div>
                    <button
                        type="submit"
                    >
                        Send Message
                    </button>
        </form>
    </div>
    );
};

export default Contact;
