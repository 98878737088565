import React, { ReactNode } from 'react';
import "./index.css";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (

      <main>
        {children}
      </main>

  );
};

export default Layout;
