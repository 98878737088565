import './index.css';
import logo_hogcloud1 from "../../assets/images/hogcloud-logo1.png";

const Footer = () => {
   return ( <>
    <div className="footer">
    <div className="footer-left">
        <a href="/"><img src={logo_hogcloud1} alt="hogcloud logo pig in cloud" 
          className="hc_logo_small responsive-logo"/> </a>&copy; 2021 - {new Date().getFullYear()} Hogcloud, Inc.
    </div>
    <div className="footer-right">
        <a href="https://hogcloud.awsapps.com/start#" target="_blank" style={{color: '#B23212'}}>AWS</a>
    </div>
    </div>
  </>);
}

export default Footer;