import React, { useState } from 'react';
import './index.css';

interface Service {
  title: string;
  description: string;
  details: string;
}

const Services: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<number | null>(0); // Default to first category
  const [flippedCards, setFlippedCards] = useState<number[]>([]);

  const handleCardFlip = (index: number) => {
    if (flippedCards.includes(index)) {
      setFlippedCards(flippedCards.filter(i => i !== index));
    } else {
      setFlippedCards([...flippedCards, index]);
    }
  };
  const serviceCategories = [
    {
      category: 'Consulting',
      services: [
        { title: 'Cloud Computing', description: 'System Design and Migrations', details: 'AWS, Azure, and GCP. Scale with cost optimization and operational excellence.' },
        { title: 'AI Services', description: '', details: 'Chatbots and RAG solutions. Augment your Business data.' },
        { title: 'Team Management', description: 'Development Team Interviewing and Management', details: 'How we manage and interview development teams effectively.' },
      ],
    },
    {
      category: 'Architecture',
      services: [
        { title: 'Cloud', description: 'Expertise in cloud system architecture.', details: 'Experience in creating scalable, reliable systems.' },
        { title: 'Web', description: 'Chatbots and Business Integrations', details: 'Web solutions for chatbots and integrations.' },
        { title: 'Mobile', description: 'Mobile system designs and strategies.', details: 'End-to-end mobile application architecture and development.' },
      ],
    },
    {
      category: 'Specialized Services',
      services: [
        { title: 'Development', description: 'Our team has experience in modern languages and frameworks.', details: 'Detailed breakdown of supported languages and frameworks.' },
        { title: 'IoT', description: 'Device integration expertise.', details: 'IoT-specific expertise, including sensors and devices.' },
        { title: 'Data', description: 'Experience with structured and non-structured datastores.', details: 'Data management and analytics services.' },
      ],
    },
  ];

  return (
    <div className="services-container">

      <div className="category-buttons">
        {serviceCategories.map((category, index) => (
          <button
            key={index}
            className={`category-button ${activeCategory === index ? 'active' : ''}`}
            onClick={() => setActiveCategory(index)}
          >
            {category.category}
          </button>
        ))}
      </div>

      <div className="service-cards">
        {serviceCategories[activeCategory!].services.map((service, index) => (
          <div key={index} className={`service-card ${flippedCards.includes(index) ? 'flipped' : ''}`} onClick={() => handleCardFlip(index)}>
            <div className="card-inner">
              <div className="card-front">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
              <div className="card-back">
                <p>{service.details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
